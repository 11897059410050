<!--
 * @Description: 商品分类二级分类列表页面
 * @Author: 小广
 * @Date: 2019-06-04 14:06:49
 * @LastEditors: 蒙川
 * @LastEditTime: 2019-08-02 18:04:13
 -->
<template>
  <div class="goodsCategorySecondList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :extraParams="extraParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button
          text="返回"
          @click="backAction"
        ></v-button>
      </template>
      <template #searchSlot>
        <v-input label="二级分类名称" v-model="searchParams.categoryAlias"></v-input>
        <v-input label="二级类目" v-model="searchParams.categoryName"></v-input>
        <v-select label="状态" v-model="searchParams.showStatus" :options="statusOps"></v-select>
        <v-input label="操作人" v-model="searchParams.operatorUserName"></v-input>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button text="查看" type="text" permission="view" @click="look(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL } from './api'
import { statusOps } from './map'

export default {
  data () {
    return {
      searchUrl: getListURL,
      statusOps: statusOps,
      searchParams: {
        categoryLevel: 2,
        categoryAlias: '',
        categoryName: '',
        operatorUserName: '',
        showStatus: undefined

      },
      extraParams: {
        parentId: this.defaultParentId(),
        regionId: this.$route.query.regionId
      },
      headers: [
        {
          prop: 'categoryAlias',
          label: '二级分类名称'
        },
        {
          prop: 'oneCategoryName',
          label: '一级类目'
        },
        {
          prop: 'twoCategoryName',
          label: '二级类目'
        },
        {
          prop: 'sort',
          label: '排序'
        },
        {
          prop: 'showStatusStr',
          label: '状态'
        },
        {
          prop: 'operatorUser',
          label: '操作人'
        },
        {
          prop: 'operatorTime',
          label: '操作时间'
        }
      ]
    }
  },

  created () {
    const { title, regionId, regionName } = this.$route.query
    this.extraParams.regionId = regionId
    this.$setBreadcrumb(`${regionName} > ${title}`)
  },
  methods: {

    // 返回按钮
    backAction () {
      this.$router.go(-1)
    },

    defaultParentId () {
      let parentId = ''
      if (this.$route.query && this.$route.query.categoryId) {
        parentId = this.$route.query.categoryId
      }
      return parentId
    },

    // 查看，进入三级分类
    look (row) {
      this.$router.push({
        name: 'goodsCategoryThirdList',
        query: {
          id: row.id,
          title: row.twoCategoryName,
          sTitle: this.$route.query.title,
          regionId: this.$route.query.regionId,
          regionName: this.$route.query.regionName,
          categoryId: row.categoryId
        }
      })
    },

    // 编辑
    edit (row) {
      this.$router.push({
        name: 'goodsCategorySecondForm',
        query: {
          id: row.id,
          regionName: this.$route.query.regionName,
          title: this.$route.query.title
        }
      })
    }
  }
}
</script>
